import React, { useState } from 'react';
import './DeleteAccount.css';

const DeleteAccount = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async () => {
        if (!email) {
            setMessage('Please provide your registered email address.');
            return;
        }

        setIsLoading(true);
        setMessage('');

        try {
            const response = await fetch('https://your-backend-url.com/api/account/delete?email=' + email, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (response.ok) {
                setMessage('Your account has been successfully deleted.');
            } else {
                setMessage('Failed to delete account. Please try again.');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="delete-account-container">
            <h1>Delete Your Account</h1>
            <p>Enter your registered email address to delete your account:</p>
            <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="delete-account-input"
            />
            <button onClick={handleDelete} disabled={isLoading} className="delete-account-button">
                {isLoading ? 'Deleting...' : 'Delete Account'}
            </button>
            {message && <p className="delete-account-message">{message}</p>}
        </div>
    );
};

export default DeleteAccount;
